import "semantic-ui-css/semantic.min.css";
// import "assets/scss/fonts.scss";

import React, { Component } from "react";
import { createGlobalStyle } from "styled-components";

import CSSPlugin from "gsap/CSSPlugin";
import BezierPlugin from "gsap/BezierPlugin";
import ScrollToPlugin from "gsap/ScrollToPlugin";

import Index from "pages/index";

const plugins = [CSSPlugin, ScrollToPlugin, BezierPlugin];

const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: "Inconsolata", monospace !important;

        &.noscroll{
          overflow: hidden;
        }
    }

    h2{
      font-weight: lighter !important;
      font-family: "Inconsolata", monospace !important;
      font-size: 40px!important;
      padding: 20px!important;
      margin: 0!important;  
      text-align: center;
      .blue{
        color: #2881B2;
      }
    }
`;

class App extends Component {
  render() {
    if (!plugins) {
      return "Plugins not initialized.";
    }

    return (
      <React.Fragment>
        <Index />
        <GlobalStyle />
      </React.Fragment>
    );
  }
}

export default App;
