import React, { Component } from 'react';
import styled from 'styled-components';

import { Container } from "semantic-ui-react";
import Menu from "components/menu/menu";
import Logo from "components/header/logo";

const StyledHeader = styled.div`
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: space-between;
`;



class Header extends Component {
    render() {
        return (
            <Container id="home">
                <StyledHeader>
                    <Logo inverted={false}/>
                    <Menu />
                </StyledHeader>
            </Container >
        );
    }
}

export default Header;
