import React, { Component } from "react";
import styled from "styled-components";

import computerImage from "assets/images/devices/computer_frame.svg";
import mobileImage from "assets/images/devices/mobile_frame_black.svg";
import tabletImage from "assets/images/devices/tablet_frame_black.svg";

import {
    Icon,
} from "semantic-ui-react";

import { colors, sizes } from "theme";

const StyledDevices = styled.ul`
    margin:0;
    padding:0;  
    display:flex;
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom:30px;
    margin-left:30px;
    z-index:4;
    li{
        display: flex;
        width: 50px;
        height: 50px;

        justify-content: center;
        align-items: center;
        border-radius: 50px;
        margin-right:10px;
        border: 1px solid ${colors.blue["50"]};

        :hover{
            background: ${colors.blue["50"]};
            cursor: pointer;
             i{
                color: white;
            }
        }

        &.active{
            background: ${colors.blue["50"]};
            i{
                color: white;
            }
        }
        i{
            color:${colors.blue["50"]};
            &.icon{
                margin: 0;
            }
        }

    }
    
`;
const StyledPortfolioDetails = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    position: fixed;
    overflow-y: scroll;
    background: rgba(255, 255, 255, 0.9);
    z-index: 3;
    top:0;
    bottom:0;
    left:0;
    right:0;
`;

const StyledIFrame = styled.iframe`
    background-color: #ffffff;
    border: none;
    z-index: 2;

    &.computer{
        width: 945px;
        height: 500px;
    }

    &.tablet{
        width: 545px;
        height: 435px;
        margin-right: 23px;
        margin-bottom: 4px;
    }

     &.mobile{
        width: 320px;
        height: 530px;
        margin-top: 0px;
        margin-left: 17px;
    }
`;

const StyledDevice = styled.div`
    position: absolute;
    background-size: contain;
    &.computer{
        background: url(${computerImage}) no-repeat center center;
        width: 1028px;
        height: 706px;
    }

    &.tablet{
        background: url(${tabletImage}) no-repeat center center;
        transform: rotate(-90deg);
        width: 1028px;
        height: 706px;
    }

    &.mobile{
        background: url(${mobileImage}) no-repeat center center;
        width: 1028px;
        height: 706px;
    }

`;

const StyledLoading = styled.div`
    text-align: center;
    color: #333333;
    z-index: 2;
    position: absolute;
    padding-bottom: 50px;
`;



const StyledClose = styled.div`
    position:absolute;
    top: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.blue["50"]};
    display: flex;
    z-index: 3;
    :hover{
        background: ${colors.blue["50"]};
        cursor: pointer;
            i{
            color: white;
        }
    }

    i{
        color: ${colors.blue["50"]};
        &.icon{
            margin: 0;
        }
    }
`;

const StyledArrow = styled.div`
    position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
    bottom: 0px;
	margin: auto;

    margin-left: ${(props) => props.left ? "300px" : "auto"};
    margin-right: ${(props) => props.right ? "300px" : "auto"};

    width: 50px;
    height: 50px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.blue["50"]};
    display: flex;
    z-index: 3;
    :hover{
        background: ${colors.blue["50"]};
        cursor: pointer;
            i{
            color: white;
        }
    }

    i{
        color: ${colors.blue["50"]};
        &.icon{
            margin: 0;
        }
    }
`;

const StyledLunch = styled.a`
    border: 1px solid ${colors.blue["50"]};
    padding: 5px 10px;
    background: #ffffff;
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: ${colors.blue["50"]};
    font-size: 14px;
    font-family: "Inconsolata",sans-serif !important;
    text-transform: uppercase;
    z-index: 5;

    :hover{
        cursor: pointer;
        background: ${colors.blue["50"]};
        color: #ffffff;
    }
`;

const StyledDeviceButton = styled.li`

    @media (max-width: ${sizes.sm}) {
        &.computer{
            display:none; 
        }
     }

       @media (max-width: ${sizes.xs}) {
        &.tablet,
        &.computer{
            display:none; 
        }

     }

`;
const StyledImages = styled.div`
    background: url(${props => require("assets/images/portfolio/" + props.image)}) no-repeat center center;
    background-size: cover;
    pointer-events: none;

    &.mobile{
        height: 530px;
        width: 320px;
        margin-left: 17px;
    }
    &.tablet{
        width: 545px;
        height: 435px;
        margin-right: 22px;
        margin-bottom: 4px;
    }
`;



const devices = [
    {
        name: "mobile",
        icon: "mobile alternate"
    }, {
        name: "tablet",
        icon: "tablet alternate"
    }, {
        name: "computer",
        icon: "computer"
    },
];



class PortfolioDetails extends Component {

    constructor(props) {
        super(props);

        this.loaded = this.loaded.bind(this);
        this.changeDevice = this.changeDevice.bind(this);
        this.slideImage = this.slideImage.bind(this);
    }

    state = {
        loaded: false,
        device: this.props.data.default,
        currentImage: 0
    }

    static defaultProps = {
    };

    componentDidMount() {
        document.body.className = 'noscroll';
    }

    componentWillUnmount() {
        document.body.className = '';
    }

    loaded(event) {
        this.setState({
            loaded: true
        })
    }


    slideImage(event, direction) {
        event.stopPropagation();

        let current = direction === "left" ? this.state.currentImage - 1 : this.state.currentImage + 1;

        if (current === this.props.data.images.length && direction === "right") {
            current = 0;
        }

        if (current === -1 && direction === "left") {
            current = this.props.data.images.length - 1
        }

        this.setState({
            currentImage: current
        });
    }

    lunchWebsite(event) {
        event.stopPropagation();

        const win = window.open("https://" + this.props.data.website, '_blank');
        win.focus();
    }

    changeDevice(event, id) {
        event.stopPropagation();

        this.setState({
            device: devices[id].name
        })


    }
    render() {
        return (
            <React.Fragment>
                <StyledPortfolioDetails onClick={this.props.onClose}>
                    {
                        this.props.data && !this.props.data.images && <StyledIFrame className={this.state.device} src={"https://" + this.props.data.website} onLoad={this.loaded}>
                        </StyledIFrame>
                    }
                    {
                        !this.state.loaded && !this.props.data.images && <StyledLoading>Loading...</StyledLoading>
                    }

                    {
                        this.props.data.images && <StyledImages image={this.props.data.images[this.state.currentImage]} className={this.state.device}></StyledImages>
                    }

                    <StyledDevices>
                        {
                            devices.map((el, i) => {

                                return this.props.data.devices.map((device, j) => {
                                    if (device === el.name) {
                                        return < StyledDeviceButton key={i} onClick={(event) => this.changeDevice(event, i)} className={el.name === this.state.device ? el.name + " active" : el.name}>
                                            <Icon name={el.icon} size="large" />
                                        </StyledDeviceButton>
                                    }
                                    return null;
                                });
                            })
                        }
                    </StyledDevices>

                    <StyledDevice className={this.state.device}></StyledDevice>

                    <StyledClose onClick={this.props.onClose} ><Icon className="close icon" size="large" /></StyledClose>

                    {
                        this.props.data.website && !this.props.data.images &&
                        <StyledLunch onClick={(event) => this.lunchWebsite(event, this.props.data.website)}>Lunch Website</StyledLunch>
                    }

                    {
                        this.props.data.images && <StyledArrow left={true} onClick={event => this.slideImage(event, "left")}><Icon className="angle double left icon" size="large" /></StyledArrow>
                    }
                    {
                        this.props.data.images && <StyledArrow right={true} onClick={event => this.slideImage(event, "right")}><Icon className="angle double right icon" size="large" /></StyledArrow>
                    }
                </StyledPortfolioDetails>
            </React.Fragment >
        );
    }
}

export default PortfolioDetails;
