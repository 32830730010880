import React, { Component } from 'react';
import styled from 'styled-components';

import logoImage from "assets/images/logo.svg";
// import logoInvertImage from "assets/images/logo_invert.svg";

const StyledLogo = styled.a`
    font-size: 28px;
    font-family: "Inconsolata", serif;
    color: #14455D;
    background: ${props=>props.invert ? "rgba(255,255,255,1)" : "transparent"} url(${logoImage})  no-repeat center center;
    background-size: ${props=>props.invert ?  "60%" : "contain"};

    border: 1px solid transparent;
    display: block; 
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    text-decoration: none;
    border-radius: 100%;
    span {
        padding-left: 70px;
    }
`;

class Logo extends Component {
    render() {
        console.log(this.props.invert===true);
        return (
            <StyledLogo invert={this.props.invert} className={"logo"} href="#home">
            {
                !this.props.invert && <span>bluetrendy</span>
            }
            </StyledLogo>
        );
    }
}

export default Logo;
